







































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ErrorComponent from '../components/ErrorComponent.vue';
import ValidationSummaryComponent from '../components/ValidationSummaryComponent.vue';
import { getVendor, createVendor, updateVendor } from '../store/rest.service';
import { Vendor, OrganizationMeta, VendorMeta, AddressMeta, DiversityDeclarationsMeta, CommonMeta } from '../store/models';
import { EmptyAddressMeta, EmptyDiversityDeclarationsMeta, EmptyVendor } from '../store/models-empty';
import PayeeHelper from '../components/PayeeHelper';
import { VendorCategory } from '../components/PayeeHelper';
import { handleExtraErrors } from '../lib/shared';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import { rules } from '../lib/validation';

import * as _ from 'lodash';

@Component({
  components: {
    ErrorComponent,
    ValidationSummaryComponent,
    SpinnerComponent,
  },
})
export default class PayeeComponent extends BaseComponent {

  @Prop() private vendorId: any;

  private orgNameRules: any[] = [ rules.required ];
  private orgCodeRules: any[] = [ rules.required ];

  private helper: PayeeHelper = new PayeeHelper(VendorCategory.None);
  private helperLoading: boolean = true;

  private vendorSaving: boolean = false;

  private editedItem: Vendor = _.cloneDeep(EmptyVendor);
  private editedVendorId: string = '';
  private vendorLoading: boolean = false;

  private arrErrors: Error[] = [];
  private extraErrors: Array<[string, string]> = [];
  private updateValidation: number = 0;

  protected mounted() {
    if (this.vendorId !== '') {
      this.editVendor(this.vendorId);
    } else {
      this.initVendor();
    }
  }

  @Watch('helper.isLoading')
  private onHelperChanged(val: any, oldVal: any) {
    this.helperLoading = this.helper.isLoading;
  }

  private resetOrgNameRules() {
    this.orgNameRules = [ rules.required ];
  }

  private isLoading(): boolean {
    return this.vendorLoading
    || this.helperLoading;
  }

  private addContact() {
    //TODO:
  }

  private updateEditedVendor(id: string) {
    this.editedVendorId = id;
    this.getVendor(this.editedVendorId);
    this.populateDataLists();
  }

  private editVendor(id: string) {
    this.updateEditedVendor(id);
  }

  private populateDataLists() {
    this.helper.getDefaultExpenseClasses();
    this.helper.getIndigenousCommunities([ 'urn:nisto-link:id:non-indigenous:no-999999' ]);
    this.helper.getDiversityReferenceData();
  }

  private initVendor() {
    this.populateDataLists();
    this.editedItem = _.cloneDeep(EmptyVendor);
    const vendorMeta = this.editedItem.vendor as VendorMeta;
    vendorMeta.vendorState = 'provisional';
  }

  private closeVendor() {
    this.$emit('close');
  }

  private save() {
    if (!this.vendorSaving) {
      if ((this.$refs.form1 as any).validate()) {
        this.updateValidation++;
        this.editedVendorId === '' ? this.createVendor() : this.updateVendor();
      } else {
        this.updateValidation++;
      }
    }
  }

  private getVendor(id: string) {
    this.vendorLoading = true;
    getVendor(id).then((response) => {
      this.editedItem = response;
      const orgMeta: OrganizationMeta = this.editedItem.organization as OrganizationMeta;
      if (!orgMeta.addresses) {
        orgMeta.addresses = [];
      }
      const addressMeta = orgMeta.addresses as AddressMeta[];
      if (addressMeta.length === 0) {
        addressMeta[0] = EmptyAddressMeta;
      }
      // Fix the annoying diversityDeclarions bug.
      const declarions: DiversityDeclarationsMeta = (this.editedItem as any).diversityDeclarions;
      if (declarions) {
        if (!this.editedItem.diversityDeclarations) {
          this.editedItem.diversityDeclarations = EmptyDiversityDeclarationsMeta;
        }
        if (declarions.indigenousImpact) { this.editedItem.diversityDeclarations.indigenousImpact = _.cloneDeep(declarions.indigenousImpact); }
        if (declarions.impactESG) { this.editedItem.diversityDeclarations.impactESG = _.cloneDeep(declarions.impactESG); }
        if (declarions.impactStatus) { this.editedItem.diversityDeclarations.impactStatus = _.cloneDeep(declarions.impactStatus); }
        if (declarions.impactSocial) { this.editedItem.diversityDeclarations.impactSocial = _.cloneDeep(declarions.impactSocial); }
        if (declarions.socialFocusedOrgs) { this.editedItem.diversityDeclarations.socialFocusedOrgs = _.cloneDeep(declarions.socialFocusedOrgs); }
        delete (this.editedItem as any).diversityDeclarions;
      }
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.vendorLoading = false;
    });
  }

  private createVendor() {
    this.vendorSaving = true;
    const orgMeta = this.editedItem.organization as OrganizationMeta;
    createVendor(this.editedItem).then((response) => {
      this.closeVendor();
      this.$emit('save', response);
    }).catch((error) => {
      this.handleVendorExtraErrors(error);
    }).finally(() => {
      this.vendorSaving = false;
    });
  }

  private updateVendor() {
    this.vendorSaving = true;
    const vendorETag = (this.editedItem.common as CommonMeta).etag as string;
    const vendorToSave = this.removeCommonProperties(_.cloneDeep(this.editedItem));
    const orgMeta = vendorToSave.organization as OrganizationMeta;
    // Convert this to a string as sometimes it is a number.
    if (orgMeta.contactPhone) {
      orgMeta.contactPhone = String(orgMeta.contactPhone as string);
    }
    updateVendor(this.editedVendorId, vendorToSave, vendorETag).then((response) => {
      this.closeVendor();
      this.$emit('save', response);
    }).catch((error) => {
      this.handleVendorExtraErrors(error);
    }).finally(() => {
      this.vendorSaving = false;
    });
  }

  private handleVendorExtraErrors(error: any) {
    this.extraErrors = [];
    handleExtraErrors(this.arrErrors, this.extraErrors, error);
    // this.extraErrors.push([ 'Organization Name', 'Organization Name must be unique.' ]);
    // Set the message on the Organization Name rules.
    const orgNameError = this.extraErrors.find((e) => e[0] === 'Organization Name');
    if (orgNameError) {
      this.orgNameRules.push(orgNameError[1]);
      (this.$refs.form1 as any).validate();
    }
    // Set the message on the Organization Code rules.
    const orgCodeError = this.extraErrors.find((e) => e[0] === 'Vendor Code');
    if (orgCodeError) {
      this.orgCodeRules.push(orgCodeError[1]);
      (this.$refs.form1 as any).validate();
    }
  }

  // The user must be a planner for the current organization to edit.
  private isEditable(): boolean {
    // return (this.editedVendorId === '' || this.isPlannerForCurrentOrg());
    return true;
  }
}
