






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ActionButtonComponent extends Vue {
  @Prop() public actions: any;
  @Prop() public icon: any;
  @Prop() public label: any;

  private expanded: boolean = false;

  private expand() {
    this.expanded = !this.expanded;
  }

  private navigate(code: string) {
    this.$emit('navigate', code);
    this.expanded = false;
  }

  private getIconClass() {
    return this.expanded ? 'v-icon-expanded' : '';
  }

  private hide(event) {
    this.expanded = false;
  }
}
