






















































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import PayeeHelper from './PayeeHelper';
import { VendorCategory } from './PayeeHelper';
import { deleteVendor, searchPayees } from '../store/rest.service';
import { ResourceSearchResult, ReferenceData, GeneralSearchCriteria, SearchParams, PayeeSearchCriteria, SortColumnMapping, AggSearchCriteria } from '../store/models';
import { EmptyAggSearchCriteria, EmptyGeneralSearchCriteria, EmptyPayeeSearchCriteria, EmptySearchParams } from '../store/models-empty';
import ErrorComponent from './ErrorComponent.vue';
import PayeeComponent from './PayeeComponent.vue';
import DeleteModalComponent from './DeleteModalComponent.vue';
import SpinnerComponent from './SpinnerComponent.vue';
import ActionButtonComponent from './ActionButtonComponent.vue';
import DialogComponent from './DialogComponent.vue';
import FiltersComponent from './FiltersComponent.vue';
import MobileBottomNavComponent from "../components/MobileBottomNavComponent.vue";
import { getTNBs, getSocialProcurement, getIS } from '../lib/aggregate';
import { getIconUrl } from '../lib/images';
import { getPagingState, handleError, setPagingState } from '../lib/shared';
import { getQsExpenseAreaFilter, setPayeeSearchFacets } from '../lib/facets';
import * as _ from 'lodash';

@Component({
  components: {
    ErrorComponent,
    PayeeComponent,
    DeleteModalComponent,
    SpinnerComponent,
    DialogComponent,
    ActionButtonComponent,
    FiltersComponent,
    MobileBottomNavComponent,
  },
})
export default class PayeesTableComponent extends BaseComponent {

  @Prop() public title: any;
  @Prop() public vendorCategory: any;
  @Prop() public hideFacets: any;
  @Prop() public hideActions: any;
  @Prop() public hideDelete: any;
  @Prop() public showVendorCode: any;

  protected baseRoute: string = '/payees';
  private searchParams: SearchParams = _.cloneDeep(EmptySearchParams);
  private payeeSearchCriteria: PayeeSearchCriteria = _.cloneDeep(EmptyPayeeSearchCriteria);
  private aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
  private searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  private pagination: any = { sortBy: 'common.displayName', descending: false, page: 1, rowsPerPage: 10 };
  private rowsPerPage: number[] = [10, 25, 100];
  private searchTerms: string = '';
  private debounceSearch = _.debounce(this.getPayees, 500);
  private getTNBs = getTNBs;
  private getSocialProcurement = getSocialProcurement;
  private getIS = getIS;

  private helper: PayeeHelper = new PayeeHelper(VendorCategory.None);
  private helperLoading: boolean = true;

  private payees: ResourceSearchResult|null = null;
  private payeesLoading: boolean = false;
  private payeeLoading: boolean = false;

  private bDeletePayeeModal: boolean = false;
  private deletePayeeId: string = '';
  private deletePayeeName: string = '';
  private deletePayeeEtag: string = '';

  private arrErrors: Error[] = [];

  private bInitialLoad: boolean = true;
  private bInitialLoadWatch: boolean = true;
  private bPagingStateSet: boolean = false;
  private editedId: string = '';
  private bEditPayee: boolean = false;
  private updatePayeeKey: number = 0;

  private sortMappings: SortColumnMapping[] = [];

  private headers: any[] = [];

  private actions: any[] = [
    { label: 'Payee', code: 'payee' },
    { label: 'Payee Expense', code: 'payee-expense' },
  ];

  protected changeCurrentOrg() {
    this.mount();
  }

  protected mounted() {
    this.mount();
  }

  @Watch('pagination', { deep: true })
  private onPaginationChanged(val: any, oldVal: any) {
    if (this.bPagingStateSet) {
      if (!_.isEqual(val, oldVal) && !this.bInitialLoad) {
        if (!this.bInitialLoadWatch) {
          this.getPayees();
        }
      }
      this.bInitialLoad = false;
      this.bInitialLoadWatch = false;
      setPagingState(this.$store, this.$route.path, this.pagination);
    } else {
      this.bPagingStateSet = true;
    }
  }

  private mount() {
    this.helper = new PayeeHelper(this.vendorCategory);
    this.bInitialLoad = true;
    // Reload the paging state from session storage.
    const pagingState: any = getPagingState(this.$store, this.$route.path);
    if (pagingState) {
      this.pagination = pagingState;
    } else {
      this.bPagingStateSet = true;
    }
    this.setHeaders();
    this.setSearchCriteria();
    this.helper.populateDataLists(this.currentOrg);
    this.getPayees();
  }

  private setSearchCriteria() {
    this.searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    // Set the Expense Area search filter.
    this.searchCriteria.filters = [];
    this.searchCriteria.filters.push(getQsExpenseAreaFilter());
    switch (this.helper.vendorCategory) {
      case VendorCategory.Client:
        this.payeeSearchCriteria.clientsOf = this.currentOrg;
        break;
      case VendorCategory.Payee:
        this.payeeSearchCriteria.payeesOf = this.currentOrg;
        this.aggSearchCriteria.hasPayee = 'id';
        this.aggSearchCriteria.hasPayer = this.currentOrg;
        break;
      case VendorCategory.Payer:
        this.payeeSearchCriteria.payersOf = this.currentOrg;
        this.aggSearchCriteria.isClient = true;
        break;
      case VendorCategory.SubVendor:
        this.payeeSearchCriteria.subVendorsOf = this.currentOrg;
        break;
      case VendorCategory.None:
        break;
    }
  }

  private getVendorCategory(): string {
    switch (this.helper.vendorCategory) {
      case VendorCategory.Payee:
        return 'payees';
      case VendorCategory.Payer:
        return 'clients';
      case VendorCategory.None:
        return 'vendors';
      default:
        return 'vendors';
    }
  }

  private setHeaders() {
    this.headers = this.helper.vendorCategory === VendorCategory.Payer ? [
      { text: 'Client Name', align: 'left', value: 'common.displayName', sortable: true, class: 'grid-header' },
      { text: 'Total Project Revenue', align: 'right', value: 'totals.revenue.revenueTotals.totalProjectRevenue', sortable: false, class: 'grid-header' },
      { text: 'Indigenous Benefits', align: 'right', sortable: false, class: 'grid-header' },
      { text: 'Total Project Expense', align: 'right', value: 'totals.expenses.expenseTotals.totalProjectSpend', sortable: false, class: 'grid-header' },
      { text: 'Direct Indigenous Spend', align: 'right', sortable: false, class: 'grid-header' },
      { text: 'Social Procurement', align: 'right', sortable: false, class: 'grid-header' },
    ] : [
      { text: 'Payee', align: 'left', value: 'common.displayName', sortable: true, class: 'grid-header' },
      { text: 'Total Spend', align: 'right', value: 'totals.expenses.expenseTotals.totalSpend', sortable: false, class: 'grid-header' },
      { text: 'Nisto Marketplace', align: 'center', sortable: false, class: 'grid-header' },
      { text: 'Payee Category', align: 'left', value: 'vendor.defaultExpenseClass', sortable: true, class: 'grid-header' },
      { text: 'Owned by Indigenous<br>Community', align: 'left', value: 'vendor.isIndigenous', sortable: false, class: 'grid-header grid-header-multiline' },
      { text: 'Supply Chain Diversity', align: 'left', sortable: false, class: 'grid-header' },
    ];
    // Only show the delete button if not hidden and not for Clients.
    if (!this.hideDelete && this.helper.vendorCategory !== VendorCategory.Payer) {
      this.headers.push({ text: 'Delete', align: 'left', sortable: false, class: 'grid-header' });
    }
  }

  private navigate(code: string) {
    this.$emit('navigate', code);
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  private closeDelete() {
    this.bDeletePayeeModal = false;
    this.getPayees();
  }

  private saveEditPayee() {
    this.updatePayeeKey++;
    this.getPayees();
  }

  private closeEditVendor() {
    this.bEditPayee = false;
  }

  private getTickIcon(): string {
    return getIconUrl('icon_tick.png');
  }

  private getEditIcon(): string {
    return getIconUrl('icon_edit.png');
  }

  private getDeleteIcon(): string {
    return getIconUrl('icon_delete.png');
  }

  private navVendor(id: string) {
    const path: string = '/'  + this.getVendorCategory() + '/id/' + id;
    this.$router.push({ path });
  }

  private deleteVendor(id: string, etag: string) {
    this.deletePayeeId = id;
    this.deletePayeeEtag = etag;
    this.bDeletePayeeModal = true;
  }

  private performDeletePayee() {
    deleteVendor(this.deletePayeeId, this.deletePayeeEtag)
    .then((response) => {
    }).catch((error) => {
    handleError(this.arrErrors, error);
    }).finally(() => {
      this.closeDelete();
    });
  }

  private updateFacetsOrFilters(facets: any) {
    this.getPayees();
  }

  private getPayees() {
    this.payeesLoading = true;
    searchPayees(this.mapSearchParams(this.pagination, this.searchTerms, this.sortMappings), this.searchCriteria, this.aggSearchCriteria, this.payeeSearchCriteria)
    .then((response) => {
      this.payees = response;
      // On the first unfiltered response set the facets.
      if (this.bInitialLoad) {
        setPayeeSearchFacets(this.searchCriteria, this.payees, [ this.helper.defaultExpenseClasses as ReferenceData ], this.helper.diversityReferenceData);
      }
    }).catch((error) => {
      this.arrErrors.push(error);
    }).finally(() => {
      this.payeesLoading = false;
      this.bInitialLoad = false;
    });
  }
}
